import { TestimonialDataInterface } from "types";
import "../index.css";


export function TestimonialCard({testimonial}:{testimonial:TestimonialDataInterface} ) {
  return (
    
    <div className="h-full p-8 rounded-2xl bg-gradient-to-b hover:cursor-pointer from-white/10 to-white/5 backdrop-blur-sm border border-white/10 hover:border-white/20 transition-colors">
    {/* <div className=" mb-3">
      <Avatar className="w-17 h-17  border-2 border-white/20">
        <AvatarImage key={testimonial.image} src={testimonial.image} alt={testimonial.name} />
         <AvatarFallback>{testimonial.name.charAt(0)}</AvatarFallback>
      </Avatar>
    </div> */}
    <div className="items-center flex gap-6 mb-2">

    <div className="relative w-24 h-24 rounded-full overflow-hidden flex items-center justify-center bg-gray-200 text-gray-700">
      <img className="w-full h-full object-cover  rounded-full" src={testimonial.image} alt={testimonial.name} key={testimonial.image}/>
    </div>
    <div>
        <h3 className="sm:text-xl text-nowrap  md:text-2xl font-semibold  tex-center text-white">
          {testimonial.name}
        </h3>
        <p className="text-lg  mb-2 text-black-300">
          {testimonial.role}
        </p> 
      </div>
    </div>
    <p className="text-black-200 leading-relaxed">
      {testimonial.content}
    </p>
  </div>
);
}
