
import { motion } from 'framer-motion'
import { Download, Star } from 'lucide-react'
import { useEffect, useState } from 'react'
import { PopupButton } from "react-calendly"
import { Button } from "../components/ui/button"
import { Card, CardContent } from "../components/ui/card"
import { CALENDLY_URL, PLAYSTORE_URL } from '../constants'


const HeroImage = "/rnt-landing-phone.mp4"

export function Landing() {
  const [text, setText] = useState('');
  const fullText = "Welcome to RentHub.";
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < fullText.length) {
      const timeout = setTimeout(() => {
        setText((prev) => prev + fullText[index]);
        setIndex((prev) => prev + 1);
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [index]);

  return (
    <div id="home" className="min-h-screen bg-gradient-to-br from-[#0ccdef] to-[#0a9bbf] relative overflow-hidden">
      <div className="relative  mt-8 z-10  mx-auto px-4 py-6">
        <main className="grid grid-cols-1 lg:grid-cols-2 md:gap-12 sm:gap-0 px-4 items-center min-h-[calc(100vh-5rem)]">
        <div className="relative w-full max-w-2xl mx-auto">
        <div className="flex justify-center items-center p-4">
      <div className="relative w-full max-w-md mx-auto">
        <Card className="overflow-hidden sm:h-[325px] md:h-[360px] lg:h-[500px] shadow-xl">
          <CardContent className="p-0 h-full">
            <video 
              className="w-full h-full object-cover" 
            loop
            autoPlay
            muted
            playsInline
            >
              <source src={HeroImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </CardContent>
        </Card>
        
        <div className="hidden lg:flex absolute lg:-top-6 lg:-right-6 right-0 top-0 w-16 h-16 bg-blue-500 rounded-full  items-center justify-center shadow-lg">
          <Star  size={32} className="text-yellow-400 animate-pulse" />
        </div>
        
        <div className="absolute hidden lg:flex lg:-bottom-6 lg:-left-6 -left-2  w-16 h-16 bg-blue-500 rounded-full  items-center justify-center shadow-lg">
          <Star  size={32} className="text-yellow-400 animate-pulse" />
        </div>
      </div>
    </div>
    </div>

          <div className="flex sm:w-full flex-col justify-center text-center lg:text-left lg:order-first">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="relative"
            >
              <h1 className="text-4xl font-bold leading-tight md:text-6xl text-white drop-shadow-sm">
                {text}
                <span className="animate-pulse">|</span>
              </h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2 }}
                className="md:mt-6 sm:mt-2 text-white/90 text-lg drop-shadow-sm sm:text-center lg:text-start"
              >
                Your all-in-one Property Management Solution. Simplify your
                property management with our comprehensive platform.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2.5 }}
                className="mt-8 flex flex-col sm:flex-row gap-4 justify-center lg:justify-start"
              >
                <Button className="rounded-full bg-secondary text-black hover:bg-secondary/90 ">
                  <a href={PLAYSTORE_URL} target="_blank" rel="noopener noreferrer" className="flex flex-row">
                    <Download className="mr-2 h-4 w-4" /> Download
                  </a>
                </Button>
                <PopupButton
                  url={CALENDLY_URL}
                  rootElement={document.getElementById("root")!}
                  text="Request demo"
                  className=" sm:text-nowrap bg-transparent border p-2 rounded-md text-white border-white hover:bg-white/10"
                />
              </motion.div>
            </motion.div>
          </div>
        </main>
      </div>
    </div>
  );
}


