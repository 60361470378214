import { NavLink } from "react-router-dom";
import { Address, NavigationLink, PlayStoreButton, SocialIcons } from "../components";
import { PLAYSTORE_URL } from "../constants";
import "../index.css";

export function Footer() {
  return (
    <footer className="bg-[#0a9bbf] py-4 px-5 ">
      <div className="md:container md:mx-auto grid grid-cols-1 md:grid-cols-3 gap-y-4 lg:gap-x-20 ml-1 mr-1">
        <div className="flex flex-col gap-y-2 md:gap-y-4 md:col-start md:mr-8 items-center md:items-start">
          <SocialIcons />
          <PlayStoreButton url={PLAYSTORE_URL} />
        </div>

        <div className="flex flex-col items-center md:items-start mb-4 md:mb-0">
          <Address />
        </div>

        <div className="flex flex-col items-center md:items-start mb-4 md:mb-0">
          <NavigationLink />
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center border-t border-gray-800 pt-3">
        <p className="mb-2 md:mb-0 text-center md:text-left">© 2025 Rent Hub</p>
        <div className="flex gap-4">
          <NavLink
            to="policy"
            className={({ isActive }) => (isActive ? "hover:underline" : undefined)}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            to="policy"
            className={({ isActive }) => (isActive ? "hover:underline" : undefined)}
          >
            Terms & Condition
          </NavLink>
        </div>
      </div>
    </footer>
  );
}
