import yvonne from "../images/MwikaliPotrait.jpg";
import javan from "../images/JavanPotrait.jpg";
import Edwin from "../images/Edwinpotrait.png";
import Stellah from "../images/StellahPotrait.jpg";
import Jerry from "../images/jerry.jpg";

import { TestimonialDataInterface } from "types";

export const TESTIMONIALS_DATA:TestimonialDataInterface[] = [
  {
    id:1,
    image: yvonne,
    name: "Yvonne Mwikali",
    role: "Beren tenant",
    content:
      "Overall, this app has saved me a lot of time and stress. I have  been using the rental house app for a few months now, and it has made my house hunting experience so much easier due to its intuitive filtering options that allow me to narrow down my search based on my preferences.",
  },
  {
    id:2,
    image: javan,
    name: "Javan Mwanza",
    role: "Beren tenant",
    content:
      "Kudos to the developers for creating such a fantastic app! The in-app messaging feature allows me to communicate directly with landlords, making the whole process convenient and efficient. Aside from that is how reliable and prompt the customer support is. Whenever I had an issue or question, the support team was quick to respond and provided helpful guidance.!",
  },
  {
    id:3,
    image: Edwin,
    name: "Edwin Odhiambo",
    role: "Beren tenant",
    content:
      "I've tried multiple rental house apps, but this one stands out from the rest.The app's extensive database ensures a wide selection of rental listings, catering to different budgets and preferences. It's refreshing to see a company that genuinely cares about its users. I highly recommend this app to anyone in search of a rental home.",
  },
  {
    id:4,
    image: Stellah,
    name: "Stellah Gathoni ",
    role: "Beren tenant",
    content:
      "This rental house app is a lifesaver! The app's smooth navigation makes it a joy to use. I appreciate the comprehensive property details, including amenities, which help me make informed decisions.",
  },
  {
    id:5,
    image: Jerry,
    name: "Jerry Rolience",
    role : "Beren tenant",
    content:"Rent Hub has made renting so much easier! I can effortlessly search for properties within my budget, automate payments, and keep track of my lease details all in one app. The communication feature has been a lifesaver for staying in touch with my landlord, and the option to be able to search for a new building without even walking around by my self has simplifies what used to be a stressful process and tiresome at the same time. It’s truly a game-changer for tenants looking for convenience and reliability"
  }
];
